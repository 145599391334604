<template>
  <div class="home-block">
    <div class="home">
      <!-- 头部开始 -->
      <div class="home-header">
        <img class="w100f" src="../../assets/images/medicine_logistics_bg.jpg" alt="" />
        <div class="bg-text">
          <div class="bg-text-left">
            <p class="font-title">物流中心建设整体解决方案</p>
            <div class="font-title-txt mt20">传世的物流团队，精通规划、运营与物流系统，针对客户现有仓配体系存在的问<br>题，通过专家诊断、运营改造，导入全新的仓配服务系统，提升仓配服务整体水<br>平。现场驻守人员保障实施质量，并对结果进行验收评定。施工关键点进行实时<br>现场指导，病对必要工序义务提出指导。如升降平台基坑的全过程指导、地面拉<br>拔力测试、设备静态、动态、压力测试等。</div>
            <!-- <div class="next-btn mt60">进一步了解</div> -->
          </div>
          <!-- <div class="bg-text-right">
            <p class="font-one">Technology leads transform, Data drives serviceValue</p>
            <p class="font-two">Transparent Word</p>
          </div> -->
        </div>
      </div>
      <!-- 头部结束 -->
      <!-- 中间内容开始 -->
      <div class="main-contain">
        <div class="h88"></div>
        <img class="w100f" src="../../assets/images/medicine_logistics_block1.png" alt="" />
        <div class="h140"></div>
        <div class="txt-box">
          <img class="w100f" src="../../assets/images/medicine_logistics_block2.png" alt="" />
          <div class="txt-box-right">
            <div class="txt-box-title">传世团队</div>
            <p>武汉链享科技拥有一支一流的医药物流专家团队，团队成员完成了国内上百座大型医药物流中心项目，具备丰富的项目经验。</p>
            <p>在致力为客户提供最完整最适合的医药物流硬件设备、管理软件和咨询服务的同时，凭借自身经验优势，为客户打造定制化的作业流程。</p>
            <p>在三方物流业务领域，武汉链享科技依托传世母公司涉药物流服务平台优势，为上下游客户提供了医药三方物流仓储资源及前置仓服务。</p>
          </div>
        </div>
        <div class="h130"></div>
      </div>
    </div>
    <mobilePage></mobilePage>
  </div>
</template>
<script>
import Box from '@/components/box/box.vue'; //盒子组件
import Title from '@/components/title/title.vue'; //标题组件
import mobilePage from '@/views/medicine/mobile/medicine_logistics.vue'//移动端页面
export default {
  components: {
    Box,
    Title,
    mobilePage
  },
  data() {
    return {
      currentIndex: 0,
     
    };
  },
  methods: {
    getShow(index){
      this.index = index;
    },
    onTabChange(index) {
      this.currentIndex = index;
      console.log(index);
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  //头部
  .home-header {
    position: relative;
    width: 100%;
    min-height: 450px;
    .bg-text {
      width: 1200px;
      // height: 200px;
      display: flex;
      justify-content: space-between;
    //   align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      .bg-text-left {
        text-align: left;
        .font-title {
          font-size: 45px;
          color: #3D3D3D;
        }
        .font-title-txt {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 30px;
          color: #3D3D3D;
        }
        .next-btn {
          width: 140px;
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          background: #4fa1a4;
          opacity: 1;
          border-radius: 24px;
          text-align: center;
          cursor: pointer;
        }
      }
      .bg-text-right {
        font-family: Source Han Sans CN;
        text-align: right;
        .font-one {
          font-size: 30px;
        }
        .font-two {
          font-size: 22px;
          line-height: 22px;
        }
      }
    }
  }
  //中间
  .main-contain {
    width: 1200px;
    .txt-box{
      width: 1100px;
      display: flex;
      align-items: center;
      margin: auto;
      img{
        width: 640px;
        margin-right: 50px;
      }
      .txt-box-right{
        text-align: left;
       .txt-box-title{
         font-size: 32px;
         margin-bottom: 44px;
       }
       p{
         font-size: 16px;
         line-height: 28px;
         text-indent: 2em;
       }
    }
    }
    
  }
}
</style>
