<template>
  <div class="mobile-home">
    <div class="mobile-home-header">
      <img src="../../../assets/mobile_images/mo_medicine_logistics_bg.png" alt="" />
      <!-- <div class="mobile-header-btn">进一步了解</div> -->
    </div>
    <div class="mobile-content">
      <mobileImg :url="img1" boxHeight="11.28rem" marginBox="0.8rem 0 0 0"></mobileImg>
      <mobileTitle titleZh="传世团队" marginTopZh=".64rem" marginBottomZh=".5rem"></mobileTitle>
      <p>
        武汉链享科技拥有一支一流的医药物流专家团队，团队成员完成了国内上百座大型医药物流中心项目，具备丰富的项目经验。
      </p>
      <p>
        在致力为客户提供最完整最适合的医药物流硬件设备、管理软件和咨询服务的同时，凭借自身经验优势，为客户打造定制化的作业流程。
      </p>
      <p>
        在三方物流业务领域，武汉链享科技依托传世母公司涉药物流服务平台优势，为上下游客户提供了医药三方物流仓储资源及前置仓服务。
      </p>
      <img class="img-top" src="../../../assets/mobile_images/mo_medicine_logistics2.png" alt="" />
    </div>
  </div>
</template>
<script>
import mobileColumnBox from '@/components/box/mobile_column_box.vue'; //盒子组件
import mobileTitle from '@/components/title/mobile_title.vue'; //标题组件
import mobileImg from '@/components/img/mobile_img.vue'; //图片组件
export default {
  components: {
    mobileTitle,
    mobileColumnBox,
    mobileImg
  },
  data() {
    return {
      img1:require('../../../assets/mobile_images/mo_medicine_logistics1.png')
    };
  },
};
</script>
<style lang="less" scoped>
.mobile-home {
  margin: 0 auto;
  .mobile-home-header {
    position: relative;
    min-height: 5rem;
    img {
      width: 100%;
    }
    .mobile-header-btn {
      position: absolute;
      left: 50%;
      bottom: 1.8rem;
      transform: translateX(-50%);
      width: 2.4rem;
      height: 0.72rem;
      line-height: 0.72rem;
      font-size: 0.28rem;
      text-align: center;
      color: #fff;
      background: #4fa1a4;
      border-radius: 0.04rem;
    }
  }
  .mobile-content {
    margin: 0 0.4rem;
    box-sizing: border-box;
    .img-box {
      height: 11.28rem;
      margin-top: 0.8rem;
      overflow-x: auto;
      overflow-y: hidden;
      box-sizing: border-box;
      img {
        height: 11.28rem;
      }
    }
    p {
      font-size: 0.28rem;
      line-height: 0.5rem;
      color: #3d3d3d;
      text-align: left;
      text-indent: 2em;
      margin-bottom: 0.1rem;
    }
    .img-top{
      width: 100%;
      margin-top: .34rem;
    }
  }
}
</style>
